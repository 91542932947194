/* General Styles */
:root {
    --primary-color: #3b82f6;
    --secondary-color: #1e40af;
    --background-color: #f3f4f6;
    --card-background: #ffffff;
    --text-primary: #111827;
    --text-secondary: #6b7280;
    --border-color: #e5e7eb;
    --hover-color: #2563eb;
    --success-color: #10b981;
    --warning-color: #f59e0b;

    /* Light Theme */
    --light-bg-color: #f3f4f6;
    --light-card-bg: #ffffff;
    --light-text-primary: #111827;
    --light-text-secondary: #6b7280;
    --light-border-color: #e5e7eb;

    /* Dark Theme */
    --dark-bg-color: #022F56;
    --dark-card-bg: #043c6c;
    --dark-text-primary: #ffffff;
    --dark-text-secondary: #a0aec0;
    --dark-border-color: #2d3748;
}

body {
    font-family: "Open Sans", sans-serif;
    background-color: var(--light-bg-color);
    color: var(--light-text-primary);
    line-height: 1.5;
    transition: background-color 0.3s ease, color 0.3s ease;
}

body[data-theme="dark"] {
    background-color: var(--dark-bg-color);
    color: var(--dark-text-primary);
}

.best-ball-dashboard {
    max-width: 1200px;
    margin: 150px auto 0;
    padding: 2rem;
}

h1 {
    font-size: 2.5rem;
    font-weight: 1400;
    text-align: center;
    color: var(--dark-text-primary);
    margin-bottom: 2rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-family: "Raleway", sans-serif;
}

body[data-theme="dark"] h1 {
    color: var(--dark-text-primary);
}

.card {
    background-color: var(--light-card-bg);
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin-bottom: 2rem;
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
}

body[data-theme="dark"] .card {
    background-color: var(--dark-card-bg);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    color: var(--dark-text-primary);
}

.card:hover {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

body[data-theme="dark"] .card:hover {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.4);
}

/* Upload Card Component */
.upload-section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.file-input {
    padding: 0.75rem;
    border: 2px solid var(--light-border-color);
    border-radius: 0.5rem;
    font-size: 1rem;
    transition: border-color 0.3s ease;
    background-color: var(--light-card-bg);
    color: var(--light-text-primary);
}

body[data-theme="dark"] .file-input {
    border-color: var(--dark-border-color);
    background-color: var(--dark-card-bg);
    color: var(--dark-text-primary);
}

.file-input:hover, .file-input:focus {
    border-color: var(--primary-color);
}

.upload-button {
    background-color: var(--primary-color);
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: background-color 0.3s ease, transform 0.1s ease;
}

.upload-button:hover {
    background-color: var(--hover-color);
    transform: translateY(-2px);
}

/* Stats Card Component */
.stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
}

.stats-grid .card {
    padding: 1.5rem;
    text-align: center;
}

.stats-grid h3 {
    font-size: 1rem;
    font-weight: 600;
    color: var(--light-text-primary);
    margin-bottom: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

body[data-theme="dark"] .stats-grid h3 {
    color: var(--dark-text-primary);
}

.stat-value {
    font-size: 2.25rem;
    font-weight: 700;
    color: var(--primary-color);
}

/* Positional Priority Card Component */
.charts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.charts-grid .card {
    min-height: 400px;
}

.charts-grid h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    text-align: center;
}

.priority-explanation {
    margin-top: 1rem;
    font-size: 0.9rem;
    color: var(--light-text-secondary);
}

body[data-theme="dark"] .priority-explanation {
    color: var(--dark-text-primary);
}

.priority-explanation ol {
    margin-top: 0.5rem;
    padding-left: 1.5rem;
}

/* Favorite Players Card Component */
.favorite-players-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 1.5rem;
    border-radius: 0.5rem;
    overflow: hidden;
}

.favorite-players-table th,
.favorite-players-table td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid var(--light-border-color);
}

body[data-theme="dark"] .favorite-players-table th,
body[data-theme="dark"] .favorite-players-table td {
    border-bottom-color: var(--dark-border-color);
}

.favorite-players-table th {
    background-color: var(--primary-color);
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.favorite-players-table tr:nth-child(even) {
    background-color: rgba(59, 130, 246, 0.05);
}

body[data-theme="dark"] .favorite-players-table tr:nth-child(even) {
    background-color: rgba(59, 130, 246, 0.1);
}

.favorite-players-table tr:hover {
    background-color: rgba(59, 130, 246, 0.1);
}

body[data-theme="dark"] .favorite-players-table tr:hover {
    background-color: rgba(59, 130, 246, 0.2);
}

.favorite-players-analysis {
    margin-top: 1.5rem;
    padding: 1.5rem;
    background-color: rgba(59, 130, 246, 0.1);
    border-radius: 0.5rem;
    font-size: 0.9rem;
    color: var(--light-text-secondary);
}

body[data-theme="dark"] .favorite-players-analysis {
    background-color: rgba(59, 130, 246, 0.2);
    color: var(--dark-text-secondary);
}

.favorite-players-analysis h4 {
    color: var(--light-text-primary);
    margin-bottom: 0.75rem;
}

body[data-theme="dark"] .favorite-players-analysis h4 {
    color: var(--dark-text-primary);
}

/* Recent Drafts Card Component */
.recent-drafts h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    text-align: center;
}

.drafts-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.draft-item {
    border: 1px solid var(--light-border-color);
    border-radius: 0.5rem;
    padding: 1.5rem;
    transition: box-shadow 0.3s ease;
}

body[data-theme="dark"] .draft-item {
    border-color: var(--dark-border-color);
}

.draft-item:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

body[data-theme="dark"] .draft-item:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.draft-id {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0.75rem;
    color: var(--primary-color);
}

.draft-players {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
}

.draft-players h4 {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: var(--light-text-secondary);
    text-transform: uppercase;
}

body[data-theme="dark"] .draft-players h4 {
    color: var(--dark-text-secondary);
}

.draft-players ul {
    list-style-type: none;
    padding-left: 0;
    font-size: 0.9rem;
}

.see-more-button {
    display: block;
    width: 100%;
    padding: 0.75rem;
    background-color: var(--secondary-color);
    color: white;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1.5rem;
    transition: background-color 0.3s ease, transform 0.1s ease;
}

.see-more-button:hover {
    background-color: var(--hover-color);
    transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 768px) {
    .best-ball-dashboard {
        padding: 1rem;
    }

    .stats-grid,
    .charts-grid {
        grid-template-columns: 1fr;
    }

    .draft-players {
        grid-template-columns: 1fr;
    }
}

/* Tournament Strategy Card Component */
.tournament-strategy {
    margin-bottom: 2rem;
  }
  
  .tournament-strategy h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .strategy-analysis {
    margin-top: 1.5rem;
  }
  
  .strategy-analysis h4 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .tournament-insight {
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: rgba(59, 130, 246, 0.1);
    border-radius: 0.5rem;
  }
  
  body[data-theme="dark"] .tournament-insight {
    background-color: rgba(59, 130, 246, 0.2);
  }
  
  .tournament-insight h5 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .tournament-insight p {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }
  
  .strategy-analysis > p {
    font-style: italic;
    margin-top: 1rem;
  }

  /* Stacks Card Component */
  .stacks-analysis {
    margin-bottom: 2rem;
  }
  
  .stacks-analysis h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .filter-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .filter-buttons button {
    margin: 0.25rem;
    padding: 0.5rem 1rem;
    border: 1px solid var(--primary-color);
    background-color: white;
    color: var(--primary-color);
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .filter-buttons button.active,
  .filter-buttons button:hover {
    background-color: var(--primary-color);
    color: white;
  }
  
  .filter-dropdowns {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .filter-dropdowns select {
    padding: 0.5rem;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: white;
    color: var(--text-primary);
  }
  
  .stacks-list {
    margin-top: 1rem;
    max-height: 400px;
    overflow-y: auto;
  }
  
  .stacks-list h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .stacks-list table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .stacks-list th,
  .stacks-list td {
    padding: 0.5rem;
    text-align: left;
    border-bottom: 1px solid var(--border-color);
  }
  
  .stacks-list th {
    background-color: var(--background-color);
    font-weight: 600;
  }
  
  .stacks-list tr:nth-child(even) {
    background-color: var(--background-color);
  }
  
  body[data-theme="dark"] .filter-buttons button {
    background-color: var(--dark-bg-color);
    color: var(--dark-text-primary);
    border-color: var(--dark-text-primary);
  }
  
  body[data-theme="dark"] .filter-buttons button.active,
  body[data-theme="dark"] .filter-buttons button:hover {
    background-color: var(--dark-text-primary);
    color: var(--dark-bg-color);
  }
  
  body[data-theme="dark"] .filter-dropdowns select {
    background-color: var(--dark-bg-color);
    color: var(--dark-text-primary);
    border-color: var(--dark-text-secondary);
  }
  
  body[data-theme="dark"] .stacks-list th {
    background-color: var(--dark-bg-color-alt);
  }
  
  body[data-theme="dark"] .stacks-list tr:nth-child(even) {
    background-color: var(--dark-bg-color-alt);
  }

  /* Team Position Card Component */
  .team-types {
    margin-bottom: 2rem;
  }
  
  .team-types h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .filter-inputs {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .filter-input {
    display: flex;
    align-items: center;
  }
  
  .filter-input label {
    margin-right: 0.5rem;
  }
  
  .filter-input input {
    width: 50px;
    padding: 0.25rem;
    border: 1px solid var(--border-color);
    border-radius: 4px;
  }
  
  .teams-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
  }
  
  .team-item {
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 1rem;
  }
  
  .team-item h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .players-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .player {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
  }
  
  .player.qb { background-color: #ffcccb; }
  .player.rb { background-color: #90ee90; }
  .player.wr { background-color: #add8e6; }
  .player.te { background-color: #ffffe0; }
  
  body[data-theme="dark"] .filter-input input {
    background-color: var(--dark-bg-color);
    color: var(--dark-text-primary);
    border-color: var(--dark-text-secondary);
  }
  
  body[data-theme="dark"] .team-item {
    background-color: var(--dark-bg-color-alt);
    border-color: var(--dark-text-secondary);
  }
  
  body[data-theme="dark"] .player {
    color: var(--dark-bg-color);
  }

  /* Team Types Card Component */
  .drafted-team-types {
    margin-bottom: 2rem;
  }
  
  .drafted-team-types h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .filter-inputs {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .filter-input {
    display: flex;
    align-items: center;
  }
  
  .filter-input label {
    margin-right: 0.5rem;
  }
  
  .filter-input input {
    width: 50px;
    padding: 0.25rem;
    border: 1px solid var(--border-color);
    border-radius: 4px;
  }
  
  .lineup-count {
    text-align: center;
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    color: var(--primary-color);
  }
  
  .teams-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
  }
  
  .team-item {
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 1rem;
  }
  
  .team-item h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .players-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .player {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
  }
  
  .player.qb { background-color: #ffcccb; }
  .player.rb { background-color: #90ee90; }
  .player.wr { background-color: #add8e6; }
  .player.te { background-color: #ffffe0; }
  
  body[data-theme="dark"] .filter-input input {
    background-color: var(--dark-bg-color);
    color: var(--dark-text-primary);
    border-color: var(--dark-text-secondary);
  }
  
  body[data-theme="dark"] .lineup-count {
    color: var(--dark-text-primary);
  }
  
  body[data-theme="dark"] .team-item {
    background-color: var(--dark-bg-color-alt);
    border-color: var(--dark-text-secondary);
  }
  
  body[data-theme="dark"] .player {
    color: var(--dark-bg-color);
  }

  /* Week 17 Game Stack Card Component */
  .week17-game-stack {
    margin-bottom: 2rem;
  }
  
  .week17-game-stack h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .filters {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .filters select {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 4px;
  }
  
  .game-stacks-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
  }
  
  .game-stack-item {
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 1rem;
  }
  
  .game-stack-item h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .players-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .player {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
  }
  
  .player.qb { background-color: #ffcccb; }
  .player.rb { background-color: #90ee90; }
  .player.wr { background-color: #add8e6; }
  .player.te { background-color: #ffffe0; }
  
  body[data-theme="dark"] .game-stack-item {
    background-color: var(--dark-bg-color-alt);
    border-color: var(--dark-text-secondary);
  }
  
  body[data-theme="dark"] .player {
    color: var(--dark-bg-color);
  }
  
  .best-ball-dashboard-message {
    text-align: center;
    padding: 50px;
    background-color: var(--light-bg-color);
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    margin: 150px auto;
    max-width: 600px;
}

.best-ball-dashboard-message h2 {
    color: var(--light-text-color);
    margin-bottom: 20px;
}

.best-ball-dashboard-message p {
    color: var(--light-text-color);
    font-size: 18px;
}

body[data-theme="dark"] .best-ball-dashboard-message {
    background-color: var(--dark-bg-color-alt);
}

body[data-theme="dark"] .best-ball-dashboard-message h2,
body[data-theme="dark"] .best-ball-dashboard-message p {
    color: var(--dark-text-color);
}

.player-cards {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.player-card {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  background-color: lightgrey; /* Default background color for undrafted players */
}

.player-card h4 {
  margin: 0;
  font-size: 1.2em;
}

.player-card p {
  margin: 5px 0;
}