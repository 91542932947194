:root {
    --light-bg-color: #fff;
    --light-bg-color-alt: #f7f7f7;
    --light-text-color: #043c6c;
    --light-background-url: url("../img/hero-bg-light.jpg");
    --light-logo-url: url("../img/logo-light.png");
    --dark-bg-color: #043c6c;
    --dark-text-color: #fff;
    --dark-background-url: url("../img/hero-bg-dark.jpg");
    --dark-logo-url: url("../img/logo-dark.png");
    --accent-color: #04e870;
    --light-tools-png: url("../img/Tools2.png");
    --dark-bg-color-alt: #022F56;
  }
  
  /*--------------------------------------------------------------
  # General
  --------------------------------------------------------------*/
  body {
    font-family: "Open Sans", sans-serif;
    color: var(--light-text-color);
    background-image: var(--light-background-url);
    background-attachment: fixed;
  }

  body::before {
    content: "";
    background: rgba(255, 255, 255, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  
  body[data-theme="dark"] {
    color: var(--dark-text-color);
    background-image: var(--dark-background-url);
  }

  body[data-theme="dark"]::before {
    background: rgba(4, 60, 108, 0.8);
  }
  
  a {
    color: var(--accent-color);
    text-decoration: none;
  }
  
  a:hover {
    color: var(--accent-color);
    text-decoration: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Raleway", sans-serif;
  }
  
  .logo img {
    content: var(--light-logo-url);
  }
  
  body[data-theme="dark"] .logo img {
    content: url("../img/logo-dark.png");
  }
  
  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: var(--accent-color);
    width: 50px; /* Increased size for better tap target on mobile */
    height: 50px; /* Increased size for better tap target on mobile */
    border-radius: 50%; /* Changed to circle for a more modern look */
    transition: all 0.4s;
    display: flex; /* Center the icon */
    justify-content: center; /* Center the icon */
    align-items: center; /* Center the icon */
  }

  .back-to-top i {
    font-size: 28px;
    color: #fff;
  }

  .back-to-top:hover {
    background: var(--accent-color);
    color: #fff;
  }

  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }

  /* Mobile Optimization */
  @media (max-width: 768px) {
    .back-to-top {
      width: 45px; /* Slightly smaller for mobile */
      height: 45px; /* Slightly smaller for mobile */
      right: 10px; /* Adjusted position */
      bottom: 10px; /* Adjusted position */
    }

    .back-to-top i {
      font-size: 24px; /* Adjust icon size for smaller button */
    }
  }
  
  /*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: #fff;
  }
  
  #preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid var(--accent-color);
    border-top-color: #fff;
    border-bottom-color: #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: animate-preloader 1s linear infinite;
  }
  
  @keyframes animate-preloader {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    transition: all 0.5s;
    background: var(--light-bg-color);
    z-index: 997;
    padding: 15px 0;
    box-shadow: 0px 2px 15px rgba(18, 66, 101, 0.08);
  }
  
  body[data-theme="dark"] #header {
    background: var(--dark-bg-color);
  }
  
  #header.header-scrolled {
    border-color: #fff;
    box-shadow: 0px 2px 15px rgba(18, 66, 101, 0.08);
  }
  
  #header .logo {
    font-size: 28px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-family: "Poppins", sans-serif;
  }
  
  #header .logo img {
    max-height: 60px;
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/

  /* Desktop Navigation */
  .navbar {
    padding: 0;
  }

  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navbar li {
    position: relative;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-size: 18px;
    color: var(--light-text-color);
    white-space: nowrap;
    transition: 0.3s;
  }

  .getstarted {
    border: none;
  }

  body[data-theme="dark"] .navbar a,
  .navbar a:focus {
    color: var(--dark-text-color);
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: var(--accent-color);
  }

  .navbar .getstarted,
  .navbar .getstarted:focus {
    background: var(--dark-bg-color);
    padding: 8px 20px;
    margin-left: 30px;
    border-radius: 4px;
    color: var(--dark-text-color);
    text-decoration: none;
    transition: ease-in-out 0.3s;
    cursor: pointer;
  }

  body[data-theme="dark"] .navbar .getstarted,
  .navbar .getstarted:focus {
    background: var(--light-bg-color);
    color: var(--light-text-color);
  }

  .navbar .getstarted:hover,
  .navbar .getstarted:focus:hover {
    color: var(--dark-text-color);
    background: var(--accent-color);
  }

  body[data-theme="dark"] .navbar .getstarted:hover,
  .navbar .getstarted:focus:hover {
    background: var(--accent-color);
  }

  .theme-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-left: 20px;
  }

  .theme-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: var(--accent-color);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px var(--accent-color);
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  /* Mobile Navigation */
  .mobile-nav-toggle {
    color: #043c6c;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }

  .mobile-nav-toggle.bi-x {
    color: #043c6c;
    z-index: 999;
  }

  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }

    body[data-theme="dark"] .mobile-nav-toggle {
      color: var(--dark-text-color);
    }

    .navbar ul {
      display: none;
    }

    .navbar.navbar-mobile ul {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #fff;
      padding-top: 55px;
      overflow-y: auto;
      transition: 0.3s;
      z-index: 999;
    }

    body[data-theme="dark"] .navbar.navbar-mobile ul {
      background: var(--dark-bg-color);
    }

    .navbar-mobile a,
    .navbar-mobile a:focus {
      padding: 15px 20px;
      font-size: 18px;
      color: #043c6c;
    }

    .navbar-mobile a:hover,
    .navbar-mobile .active,
    .navbar-mobile li:hover>a {
      color: #04e870;
    }

    .navbar-mobile .getstarted,
    .navbar-mobile .getstarted:focus {
      margin: 15px;
      margin-bottom: 30px;
    }

    .navbar-mobile .dropdown ul {
      position: static;
      display: none;
      margin: 10px 20px;
      padding: 10px 0;
      z-index: 99;
      opacity: 1;
      visibility: visible;
      background: #fff;
      box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    }

    body[data-theme="dark"] .navbar-mobile .dropdown ul {
      background: var(--dark-bg-color);
    }

    .navbar-mobile .dropdown ul li {
      min-width: 200px;
    }

    .navbar-mobile .dropdown ul a {
      padding: 10px 20px;
    }

    .navbar-mobile .dropdown ul a:hover,
    .navbar-mobile .dropdown ul .active:hover,
    .navbar-mobile .dropdown ul li:hover>a {
      color: #04e870;
    }

    .navbar-mobile .dropdown>.dropdown-active {
      display: block;
    }

    .theme-switch {
      margin-left: 0px;
    }
  }

  /*--------------------------------------------------------------
  # Sign In Modal Section
  --------------------------------------------------------------*/
  .sign-in-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .sign-in-form {
    background-color: var(--light-bg-color);
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 29px 0 rgba(18, 66, 101, 0.08);
    max-width: 400px;
    width: 100%;
  }
  
  body[data-theme="dark"] .sign-in-form {
    background-color: var(--dark-bg-color-alt);
    color: var(--dark-text-color);
  }
  
  .sign-in-form h2 {
    margin-bottom: 20px;
    color: var(--light-text-color);
    font-family: "Raleway", sans-serif;
  }
  
  body[data-theme="dark"] .sign-in-form h2 {
    color: var(--dark-text-color);
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .btn-signin, .btn-cancel {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.5px;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    transition: 0.3s;
    margin-right: 10px;
    border: none;
    cursor: pointer;
  }
  
  .btn-signin {
    color: var(--dark-text-color);
    background: var(--dark-bg-color);
  }
  
  .btn-cancel {
    color: var(--light-text-color);
    background: #f8f9fa;
  }
  
  body[data-theme="dark"] .btn-signin {
    background: var(--dark-text-color);
    color: var(--dark-bg-color);
  }
  
  body[data-theme="dark"] .btn-cancel {
    background: var(--dark-bg-color);
    color: var(--dark-text-color);
  }
  
  .btn-signin:hover, .btn-cancel:hover {
    background: var(--accent-color);
    color: #fff;
  }
  
  @media (max-width: 991px) {
    .sign-in-form {
      margin: 0 20px;
    }
  }
  
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  #hero {
    width: 100%;
    height: 100%;
    background: transparent;
  }

  #hero .container {
    padding-top: 80px;
  }

  #hero h1 {
    margin: 0;
    font-size: 64px;
    font-weight: 700;
    line-height: 72px;
    color: var(--light-text-color);
    font-family: "Poppins", sans-serif;
  }

  body[data-theme="dark"] #hero h1 {
    color: var(--dark-text-color);
  }

  #hero h2 {
    color: #5e5e5e;
    margin: 10px 0 0 0;
    font-size: 26px;
  }

  body[data-theme="dark"] #hero h2 {
    color: #F1F1F1;
  }

  #hero .btn-get-started {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 22px;
    letter-spacing: 0.5px;
    display: inline-block;
    padding: 14px 50px;
    border-radius: 5px;
    transition: 0.5s;
    margin-top: 30px;
    color: var(--dark-text-color);
    background: var(--dark-bg-color);
    border: none;
  }

  body[data-theme="dark"] #hero .btn-get-started {
    background: var(--dark-text-color);
    color: var(--dark-bg-color);
  }

  #hero .btn-get-started:hover {
    background: var(--accent-color);
  }

  body[data-theme="dark"] #hero .btn-get-started:hover {
    background: var(--accent-color);
  }

  #hero .icon-boxes {
    margin-top: 100px;
  }

  #hero .icon-box {
    padding: 30px 30px;
    position: relative;
    overflow: hidden;
    background: var(--light-bg-color);
    box-shadow: 0 0 29px 0 rgba(18, 66, 101, 0.08);
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    z-index: 1;
  }

  body[data-theme="dark"] #hero .icon-box {
    background: #022F56;
  }

  #hero .icon-box .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 22px;
  }

  #hero .icon-box .title a {
    color: var(--light-text-color);
    transition: 0.3s;
    text-decoration: none;
  }

  body[data-theme="dark"] #hero .icon-box .title a {
    color: var(--dark-text-color);
  }

  #hero .icon-box .description {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 0;
  }

  body[data-theme="dark"] #hero .icon-box {
    color: var(--dark-text-color);
  }

  #hero .icon-box .icon {
    margin-bottom: 20px;
    padding-top: 10px;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    font-size: 36px;
    line-height: 1;
    color: #04e870;
  }

  #hero .icon-box .icon img {
    max-width: 50px;
    max-height: 50px;
  }

  #hero .icon-box .icon-tools img {
    max-width: 50px;
    max-height: 50px;
  }

  body[data-theme="dark"] #hero .icon-box .icon-tools img {
    content: var(--light-tools-png);
  }

  #hero .icon-box .btn-icon {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.5px;
    display: block;
    padding: 14px 35px;
    border-radius: 5px;
    transition: 0.5s;
    margin: 25px auto 0 auto;
    color: #fff;
    background: #043c6c;
    border: none;
    min-width: 200px;
  }

  body[data-theme="dark"] #hero .icon-box .btn-icon {
    background: var(--dark-text-color);
    color: var(--dark-bg-color);
  }

  #hero .icon-box .btn-icon:hover {
    background: var(--accent-color);
  }

  body[data-theme="dark"] #hero .icon-box .btn-icon:hover {
    background: var(--accent-color);
  }

  #hero .icon-box:hover {
    transform: scale(1.08);
  }

  @media (max-width: 1399px) {
    #hero .icon-box .btn-icon {
      font-size: 14px;
    }

    #hero .icon-box .title {
      font-size: 20px;
    }

    #hero .icon-box .description {
      font-size: 16px;
    }

    #hero .icon-box {
      padding: 15px 20px;
    }
  }

  @media (max-width: 767px) {
    #hero h1 {
      font-size: 46px;
      line-height: 56px;
    }

    #hero h2 {
      font-size: 20px;
    }

    #hero .btn-get-started {
      font-size: 16px;
    }

    #hero .icon-boxes {
      margin-top: 60px;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    #hero .icon-box .btn-icon {
      min-width: 170px;
      font-size: 14px;
      padding: 15px 10px;
    }
  }

  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 80px 0;
    overflow: hidden;
  }

  .section-bg {
    background-color: var(--light-bg-color);
  }

  .section-bg-alt {
    background-color: var(--light-bg-color-alt);
  }

  body[data-theme="dark"] .section-bg-alt {
    background-color: var(--dark-bg-color-alt);
  }

  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }

  .section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: var(--light-text-color);
  }

  body[data-theme="dark"] .section-title h2 {
    color: var(--dark-text-color);
  }

  .section-title p {
    margin-bottom: 0;
    font-size: 14px;
    color: #5e5e5e;
  }

  body[data-theme="dark"] .section-title p {
    color: #dedede;
  }
  
  /*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
  .about .content h3 {
    font-weight: 600;
    font-size: 26px;
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    padding-left: 28px;
    position: relative;
  }
  
  .about .content .features-list li span {
    font-weight: 700;
  }
  
  .about .content ul li+li {
    margin-top: 40px;
  }
  
  .about .content ul i {
    position: absolute;
    left: 0;
    font-size: 25px;
    color: var(--accent-color);
    line-height: 1;
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }
  
  .about .content .btn-learn-more {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: var(--light-text-color);
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid var(--light-text-color);
  }
  
  body[data-theme="dark"] .about .content .btn-learn-more {
    color: var(--dark-text-color);
    border: 2px solid var(--dark-text-color);
  }
  
  .about .content .btn-learn-more:hover {
    background: var(--accent-color);
    color: var(--light-text-color);
    text-decoration: none;
  }
  
  body[data-theme="dark"] .about .content .btn-learn-more:hover {
    background: var(--accent-color);
    color: var(--light-text-color);
  }

  @media (max-width: 991px) {
    
  }
  
  /*--------------------------------------------------------------
  # About Video
  --------------------------------------------------------------*/
  .about-video .content {
    font-size: 15px;
  }
  
  .about-video .content h3 {
    font-weight: 700;
    font-size: 24px;
    color: #124265;
  }
  
  .about-video .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about-video .content ul li {
    padding-bottom: 10px;
    padding-left: 28px;
    position: relative;
  }
  
  .about-video .content ul i {
    font-size: 24px;
    color: #2487ce;
    position: absolute;
    left: 0;
    top: -2px;
  }
  
  .about-video .content p:last-child {
    margin-bottom: 0;
  }
  
  .about-video .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#2487ce 50%, rgba(36, 135, 206, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
  }
  
  .about-video .play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .about-video .play-btn::before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    animation-delay: 0s;
    animation: pulsate-btn 2s;
    animation-direction: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(36, 135, 206, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  
  .about-video .play-btn:hover::after {
    border-left: 15px solid #2487ce;
    transform: scale(20);
  }
  
  .about-video .play-btn:hover::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    animation: none;
    border-radius: 0;
  }
  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
  
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Clients
  --------------------------------------------------------------*/
  .clients {
    padding: 15px 0;
    text-align: center;
  }
  
  .clients img {
    max-width: 45%;
    transition: all 0.4s ease-in-out;
    display: inline-block;
    padding: 15px 0;
    filter: grayscale(100);
  }
  
  .clients img:hover {
    filter: none;
    transform: scale(1.15);
  }
  
  @media (max-width: 768px) {
    .clients img {
      max-width: 40%;
    }
  }
  
  /*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
  .testimonials {
    margin-top: -120px;
  }

  .testimonials .testimonials-carousel,
  .testimonials .testimonials-slider {
    overflow: hidden;
  }

  .testimonials .testimonial-item {
    box-sizing: content-box;
    text-align: center;
    min-height: 320px;
  }

  .testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50%;
    margin: 0 auto;
  }

  .testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: var(--light-text-color);
  }

  body[data-theme="dark"] .testimonials .testimonial-item h3 {
    color: var(--dark-text-color);
  }

  .testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
  }

  .testimonials .testimonial-item .quote-icon-left,
  .testimonials .testimonial-item .quote-icon-right {
    color: rgba(var(--dark-bg-color), 0.8);
    font-size: 26px;
  }

  .testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }

  .testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
  }

  .testimonials .testimonial-item p {
    font-style: italic;
    margin: 15px;
    padding: 20px;
    background: var(--dark-bg-color);
    color: var(--dark-text-color);
    position: relative;
    margin-bottom: 35px;
    border-radius: 6px;
  }

  body[data-theme="dark"] .testimonials .testimonial-item p {
    background: var(--light-bg-color-alt);
    color: var(--light-text-color);
  }

  .testimonials .testimonial-item p::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 20px solid var(--dark-bg-color);
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    position: absolute;
    bottom: -20px;
    left: calc(50% - 20px);
  }

  .testimonials .swiper-pagination {
    margin-top: 10px;
    position: relative;
  }

  .testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: var(--dark-bg-color);
    opacity: 1;
    border: none;
  }

  body[data-theme="dark"] .testimonials .swiper-pagination .swiper-pagination-bullet {
    background-color: var(--light-bg-color-alt);
  }

  .testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--accent-color);
  }

  body[data-theme="dark"] .testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--accent-color);
  }

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services {
  background: transparent;
}

.tool-link {
  display: block;
  height: 100%;
  text-decoration: none;
  color: var(--dark-text-color);
}

.tool-link:hover {
  text-decoration: none;
}

.icon-box {
  height: 100%;
}

.services .icon-box {
  text-align: center;
  padding: 70px 20px 80px 20px;
  transition: all ease-in-out 0.3s;
  background: var(--dark-bg-color-alt);
  box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.05);
  border-radius: 10px;
  height: 100%;
}

.services .icon-box .icon {
  margin: 0 auto;
  padding-top: 15px;
  padding-left: 15px;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
  position: relative;
  color: var(--dark-bg-color);
}

.services .icon-box .icon i {
  font-size: 36px;
  transition: 0.5s;
  position: relative;
}

.services .icon-box .icon svg {
  position: absolute;
  top: 0;
  left: 0;
}

.services .icon-box .icon svg path {
  transition: 0.5s;
  fill: var(--light-bg-color-alt);
}

.services .icon-box h4 {
  font-weight: 600;
  margin: 30px 0 15px 0;
  font-size: 20px;
}

.services .icon-box h4 a {
  color: var(--dark-text-color);
  transition: ease-in-out 0.3s;
  text-decoration: none;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
  color: var(--dark-text-color);
}

.services .icon-box:hover {
  border-color: var(--light-bg-color);
  box-shadow: 0px 0 35px 0 rgba(0, 0, 0, 0.08);
}

/* Keep your existing color-specific styles here */

/* Responsive adjustments */
@media (max-width: 992px) {
  .services .icon-box {
    margin-bottom: 20px;
  }
}
  
  /*--------------------------------------------------------------
  # Cta
  --------------------------------------------------------------*/
  .cta {
    background: var(--dark-bg-color-alt);
    background-size: cover;
    padding: 80px 0;
  }
  
  .cta h3 {
    color: var(--dark-text-color);
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .cta p {
    color: var(--dark-text-color);
    margin-bottom: 20px;
  }
  
  .cta .cta-btn {
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 15px 35px;
    border-radius: 5px;
    transition: 0.5s;
    margin-top: 10px;
    border: none;
    background-color: var(--light-bg-color);
    color: var(--light-text-color);
  }
  
  .cta .cta-btn:hover {
    background: var(--accent-color);
    color: var(--dark-text-color);
    border: none;
  }
  
  /*--------------------------------------------------------------
  # Portfolio
  --------------------------------------------------------------*/
  .portfolio .portfolio-item {
    margin-bottom: 30px;
  }
  
  .portfolio #portfolio-flters {
    padding: 0;
    margin: 0 auto 35px auto;
    list-style: none;
    text-align: center;
    border-radius: 50px;
    padding: 2px 15px;
  }
  
  .portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 10px 15px 8px 15px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    color: #444444;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
  }
  
  .portfolio #portfolio-flters li:hover,
  .portfolio #portfolio-flters li.filter-active {
    color: #2487ce;
  }
  
  .portfolio #portfolio-flters li:last-child {
    margin-right: 0;
  }
  
  .portfolio .portfolio-wrap {
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  
  .portfolio .portfolio-wrap::before {
    content: "";
    background: rgba(36, 135, 206, 0.6);
    position: absolute;
    left: 30px;
    right: 30px;
    top: 30px;
    bottom: 30px;
    transition: all ease-in-out 0.3s;
    z-index: 2;
    opacity: 0;
  }
  
  .portfolio .portfolio-wrap .portfolio-info {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .portfolio .portfolio-wrap .portfolio-info::before {
    display: block;
    content: "";
    width: 48px;
    height: 48px;
    position: absolute;
    top: 35px;
    left: 35px;
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    transition: all 0.5s ease 0s;
    z-index: 9994;
  }
  
  .portfolio .portfolio-wrap .portfolio-info::after {
    display: block;
    content: "";
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: 35px;
    right: 35px;
    border-bottom: 3px solid #fff;
    border-right: 3px solid #fff;
    transition: all 0.5s ease 0s;
    z-index: 9994;
  }
  
  .portfolio .portfolio-wrap .portfolio-info h4 {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
  }
  
  .portfolio .portfolio-wrap .portfolio-info p {
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
  }
  
  .portfolio .portfolio-wrap .portfolio-links {
    text-align: center;
    z-index: 4;
  }
  
  .portfolio .portfolio-wrap .portfolio-links a {
    color: rgba(255, 255, 255, 0.6);
    margin: 0 2px;
    font-size: 28px;
    display: inline-block;
    transition: 0.3s;
  }
  
  .portfolio .portfolio-wrap .portfolio-links a:hover {
    color: #fff;
  }
  
  .portfolio .portfolio-wrap:hover::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
  }
  
  .portfolio .portfolio-wrap:hover .portfolio-info {
    opacity: 1;
  }
  
  .portfolio .portfolio-wrap:hover .portfolio-info::before {
    top: 15px;
    left: 15px;
  }
  
  .portfolio .portfolio-wrap:hover .portfolio-info::after {
    bottom: 15px;
    right: 15px;
  }
  
  /*--------------------------------------------------------------
  # Portfolio Details
  --------------------------------------------------------------*/
  .portfolio-details {
    padding-top: 40px;
  }
  
  .portfolio-details .portfolio-details-slider img {
    width: 100%;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #2487ce;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #2487ce;
  }
  
  .portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(18, 66, 101, 0.08);
  }
  
  .portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
  
  .portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .portfolio-details .portfolio-info ul li+li {
    margin-top: 10px;
  }
  
  .portfolio-details .portfolio-description {
    padding-top: 30px;
  }
  
  .portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .portfolio-details .portfolio-description p {
    padding: 0;
  }
  
  /*--------------------------------------------------------------
  # Team
  --------------------------------------------------------------*/
  .team .member {
    margin-bottom: 20px;
    overflow: hidden;
    text-align: center;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(18, 66, 101, 0.08);
  }
  
  .team .member .member-img {
    position: relative;
    overflow: hidden;
  }
  
  .team .member .social {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 40px;
    opacity: 0;
    transition: ease-in-out 0.3s;
    text-align: center;
    background: rgba(255, 255, 255, 0.85);
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .team .member .social a {
    transition: color 0.3s;
    color: #124265;
    margin: 0 10px;
    display: inline-block;
  }
  
  .team .member .social a:hover {
    color: #2487ce;
  }
  
  .team .member .social i {
    font-size: 18px;
    margin: 0 2px;
    line-height: 0;
  }
  
  .team .member .member-info {
    padding: 25px 15px;
  }
  
  .team .member .member-info h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 18px;
    color: #124265;
  }
  
  .team .member .member-info span {
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: #aaaaaa;
  }
  
  .team .member .member-info p {
    font-style: italic;
    font-size: 14px;
    line-height: 26px;
    color: #777777;
  }
  
  .team .member:hover .social {
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing {
  background: transparent;
}

.pricing .box {
  padding: 40px 20px;
  text-align: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  background: var(--dark-bg-color-alt);
  box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.1);
  transition: all 0.3s ease-in-out;
}

.pricing .box h3 {
  font-weight: 400;
  padding: 15px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  color: #bababa;
}

.pricing .box h4 {
  font-size: 42px;
  color: #bababa;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 20px;
}

.pricing .box h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing .box h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.pricing .box ul {
  padding: 0;
  list-style: none;
  color: #bababa;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.pricing .box ul li {
  padding-bottom: 16px;
}

.pricing .box ul .na {
  color: #bababa;
  text-decoration: line-through;
}

.pricing .box .btn-wrap {
  padding: 15px;
  text-align: center;
}

.pricing .box .btn-buy {
  display: inline-block;
  padding: 10px 40px;
  border-radius: 4px;
  color: #bababa;
  transition: 0.3s;
  font-size: 14px;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  border: 2px solid #bababa;
  text-decoration: none;
}

.pricing .box .btn-buy:hover {
  background: var(--accent-color);
  color: #fff;
  border: 2px solid var(--accent-color);
}

.pricing .featured h3,
.pricing .featured h4 span,
.pricing .featured ul,
.pricing .featured ul .na {
  color: var(--dark-text-color);
}

.pricing .featured h4 {
  color: var(--accent-color);
}

.pricing .featured .btn-buy {
  color: var(--dark-text-color);
  border: 2px solid var(--dark-text-color);
}

.pricing .featured .btn-buy:hover {
  background: var(--accent-color);
  color: var(--dark-text-color);
  border: 2px solid var(--accent-color);
}

@media (min-width: 992px) {
  .pricing .box {
    transform: scale(0.9);
  }
  
  .pricing .featured {
    transform: scale(1);
    z-index: 1;
  }
  
  .pricing-row {
    display: flex;
    align-items: center;
  }
  
  .featured-plan {
    order: 0;
  }
}

@media (max-width: 991px) {
  .pricing-row {
    flex-direction: column;
  }
  
  .featured-plan {
    order: -1;
  }
  
  .pricing .box {
    margin-bottom: 30px;
  }
}
  
  /*--------------------------------------------------------------
  # Frequently Asked Questions
  --------------------------------------------------------------*/
  .faq .faq-list {
    padding: 0 100px;
  }
  
  .faq .faq-list ul {
    padding: 0;
    list-style: none;
  }
  
  .faq .faq-list li+li {
    margin-top: 15px;
  }
  
  .faq .faq-list li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    position: relative;
  }
  
  .faq .faq-list a {
    display: block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 0 30px;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    color: var(--light-text-color);
  }
  
  .faq .faq-list .icon-help {
    font-size: 24px;
    position: absolute;
    right: 0;
    left: 20px;
    color: var(--accent-color);
  }
  
  .faq .faq-list .icon-show,
  .faq .faq-list .icon-close {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .faq .faq-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
  }

  body[data-theme="dark"] .faq .faq-list p {
    color: var(--light-text-color);
  }
  
  .faq .faq-list .icon-show {
    display: none;
  }
  
  .faq .faq-list a.collapsed {
    color: var(--light-text-color);
  }
  
  .faq .faq-list a.collapsed:hover {
    color: var(--dark-bg-color-alt);
  }
  
  .faq .faq-list a.collapsed .icon-show {
    display: inline-block;
  }
  
  .faq .faq-list a.collapsed .icon-close {
    display: none;
  }
  
  @media (max-width: 1200px) {
    .faq .faq-list {
      padding: 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
  .contact .info {
    width: 100%;
  }

  .contact .section-title {
    padding-bottom: 0;
  }
  
  .contact .info i {
    font-size: 20px;
    color: var(--light-text-color);
    float: left;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
  }
  
  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: var(--light-text-color);
  }
  
  .contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: var(--light-text-color);
  }
  
  .contact .info .email,
  .contact .info .phone {
    margin-top: 40px;
  }
  
  .contact .info .email:hover i,
  .contact .info .address:hover i,
  .contact .info .phone:hover i {
    color: #fff;
  }
  
  .contact .php-email-form {
    width: 100%;
    background: transparent;
  }
  
  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .error-message br+br {
    margin-top: 25px;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: var(--accent-color);
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid var(--accent-color);
    border-top-color: #eee;
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input,
  .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
  }
  
  .contact .php-email-form input:focus,
  .contact .php-email-form textarea:focus {
    border-color: var(--light-text-color);
  }
  
  .contact .php-email-form input {
    height: 44px;
  }
  
  .contact .php-email-form textarea {
    padding: 10px 12px;
  }
  
  .contact .php-email-form button[type=submit] {
    background: var(--light-text-color);
    border: 0;
    padding: 10px 30px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
    font-weight: 600;
  }

  body[data-theme="dark"] .contact .php-email-form button[type=submit] {
    background: var(--light-bg-color-alt);
    color: var(--light-text-color)
  }
  
  .contact .php-email-form button[type=submit]:hover {
    background: var(--accent-color);
  }

  body[data-theme="dark"] .contact .php-email-form button[type=submit]:hover {
    background: var(--accent-color);
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 991px) {
    .contact .section-title {
      margin-bottom: -40px;
    }
  }
  
  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
  .breadcrumbs {
    padding: 15px 0;
    background: #f8fbfe;
    min-height: 40px;
    margin-top: 72px;
  }
  
  @media (max-width: 992px) {
    .breadcrumbs {
      margin-top: 58px;
    }
  }
  
  .breadcrumbs h2 {
    font-size: 24px;
    font-weight: 300;
  }
  
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
  
  .breadcrumbs ol li+li {
    padding-left: 10px;
  }
  
  .breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #1a5e90;
    content: "/";
  }
  
  @media (max-width: 768px) {
    .breadcrumbs .d-flex {
      display: block !important;
    }
  
    .breadcrumbs ol {
      display: block;
    }
  
    .breadcrumbs ol li {
      display: inline-block;
    }
  }
  
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  #footer {
    color: #444444;
    font-size: 14px;
    background: #f8fbfe;
    box-shadow: 0px 2px 15px rgba(18, 66, 101, 0.08);
  }
  
  #footer .footer-top {
    padding: 60px 0 30px 0;
    background: #fff;
  }
  
  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-contact h3 {
    font-size: 26px;
    margin: 0 0 10px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 300;
  }
  
  #footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
    color: #777777;
  }
  
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: #124265;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #2487ce;
    font-size: 18px;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footer .footer-top .footer-links ul a {
    color: #777777;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: #2487ce;
  }
  
  #footer .footer-newsletter {
    font-size: 15px;
  }
  
  #footer .footer-newsletter h4 {
    font-size: 16px;
    font-weight: bold;
    color: #444444;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 5px;
    text-align: left;
    border: 1px solid #cde5f6;
  }
  
  #footer .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px 8px;
    width: calc(100% - 100px);
  }
  
  #footer .footer-newsletter form input[type=submit] {
    position: absolute;
    top: -1px;
    right: -2px;
    bottom: -1px;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px 2px 20px;
    background: #2487ce;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 5px 5px 0;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  #footer .footer-newsletter form input[type=submit]:hover {
    background: #3194db;
  }
  
  #footer .credits {
    padding-top: 5px;
    font-size: 13px;
    color: #444444;
  }
  
  #footer .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #2487ce;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 5px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .social-links a:hover {
    background: #3194db;
    color: #fff;
    text-decoration: none;
  }