:root {
  --light-bg-color: #fff;
  --light-bg-color-alt: #f7f7f7;
  --light-text-color: #043c6c;
  --dark-bg-color: #043c6c;
  --dark-bg-color-alt: #022F56;
  --dark-text-color: #fff;
  --accent-color: #04e870;
}

.air-yards-dashboard {
  font-family: "Open Sans", sans-serif;
  max-width: 1200px;
  margin: 150px auto 0;
  margin-bottom: 100px;
  padding: 2rem;
  background-color: var(--light-bg-color);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

body[data-theme="dark"] .air-yards-dashboard {
  background-color: var(--dark-bg-color-alt);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.player-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.player-card {
  border: 1px solid var(--light-text-color);
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  background-color: var(--light-bg-color);
  transition: all 0.3s ease;
}

body[data-theme="dark"] .player-card {
  border-color: var(--dark-text-color);
  background-color: var(--dark-bg-color);
}

.player-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

body[data-theme="dark"] .player-card:hover {
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.1);
}

.player-card h4 {
  margin: 0;
  font-size: 1.2em;
  color: var(--light-text-color);
}

body[data-theme="dark"] .player-card h4 {
  color: var(--dark-text-color);
}

.player-card p {
  margin: 10px 0;
  color: var(--light-text-color);
}

body[data-theme="dark"] .player-card p {
  color: var(--dark-text-color);
}

/* Add some flair with a decorative element */
.air-yards-dashboard::before {
  content: "🏈";
  font-size: 3em;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .air-yards-dashboard {
    padding: 1rem;
    margin-top: 100px; /* Remove top margin on mobile */
    margin-bottom: 0px;
    border-radius: 0; /* Remove border radius on mobile for full-width appearance */
  }

  .style-matchup {
    margin-bottom: -75px;
  }

  .team-section {
    margin-bottom: -75px;
  }

  .player-section {
    margin-bottom: -75px;
  }

  /* Improve chart readability on mobile */
  .chart-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  /* Ensure top and bottom parts don't move when scrolling charts */
  .chart-header,
  .chart-footer {
    position: sticky;
    background-color: var(--light-bg-color);
    z-index: 1;
  }

  body[data-theme="dark"] .chart-header,
  body[data-theme="dark"] .chart-footer {
    background-color: var(--dark-bg-color-alt);
  }

  .chart-header {
    top: 0;
  }

  .chart-footer {
    bottom: 0;
  }

  /* Remove the decorative element on mobile */
  .air-yards-dashboard::before {
    display: none;
  }
}