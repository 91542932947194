.table-container {
  margin-top: 30px;
  overflow-x: auto;
  background-color: var(--light-bg-color-alt);
  border-radius: 10px;
  transition: all 0.3s ease;
}

body[data-theme="dark"] .table-container {
  background-color: var(--dark-bg-color-alt);
}

.player-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-family: "Open Sans", sans-serif;
}

.player-table th,
.player-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid var(--light-bg-color-alt);
}

body[data-theme="dark"] .player-table th,
body[data-theme="dark"] .player-table td {
  border-bottom-color: var(--dark-bg-color);
}

.player-table th {
  background-color: var(--light-text-color);
  color: var(--light-bg-color);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 0.9em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

body[data-theme="dark"] .player-table th {
  background-color: var(--light-bg-color);
  color: var(--light-text-color);
}

.player-table th:hover {
  background-color: var(--accent-color);
}

body[data-theme="dark"] .player-table th:hover {
  background-color: var(--accent-color);
  color: var(--dark-bg-color);
}

.player-table tr:nth-child(even) {
  background-color: var(--light-bg-color);
}

body[data-theme="dark"] .player-table tr:nth-child(even) {
  background-color: var(--dark-bg-color);
}

.player-table tr:hover {
  background-color: rgba(4, 232, 112, 0.1);
}

body[data-theme="dark"] .player-table tr:hover {
  background-color: rgba(4, 232, 112, 0.2);
}

.sort-indicator {
  margin-left: 5px;
  font-size: 0.8em;
}

@media (max-width: 768px) {
  .table-container {
    margin-top: 0;
    border-radius: 0;
  }

  .search-controls {
    flex-direction: column;
  }

  .search-controls input,
  .search-controls .team-select,
  .search-controls select {
    width: 100%;
    margin: 0.5rem 0;
  }

  .player-table {
    font-size: 16px;
  }

  .player-table th,
  .player-table td {
    padding: 8px;
  }

  /* Make the table header sticky */
  .player-table thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  /* Add horizontal scrolling for the table */
  .table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 480px) {
  .player-table th,
  .player-table td {
    padding: 8px;
    font-size: 0.8em;
  }
}

@keyframes highlight {
   0% { background-color: rgba(4, 232, 112, 0.2); }
  100% { background-color: transparent; }
}

.player-table tr {
  animation: highlight 1s ease-out;
}

.table-controls {
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

body[data-theme="dark"] .table-controls {
  background-color: var(--dark-bg-color);
}

.table-controls button {
  padding: 0.5rem 1rem;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 1rem;
}

.table-controls button:hover {
  background-color: #e0e0e0;
}

.search-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--light-bg-color-alt);
  border-radius: 10px 10px 0 0;
}

body[data-theme="dark"] .search-controls {
  background-color: var(--dark-bg-color);
}

.search-controls input,
.search-controls .team-select {
  flex: 1;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border: 1px solid var(--light-text-color);
  border-radius: 4px;
  font-size: 0.9em;
  background-color: var(--light-bg-color);
  color: var(--light-text-color);
  transition: all 0.3s ease;
}

body[data-theme="dark"] .search-controls input,
body[data-theme="dark"] .search-controls .team-select {
  border-color: var(--dark-text-color-alt);
  background-color: var(--dark-bg-color-alt);
  color: var(--dark-text-color);
}

.search-controls input:focus,
.search-controls .team-select:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgba(4, 232, 112, 0.2);
}

.search-controls .team-select {
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23333' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%;
  background-size: 0.65em auto;
  padding-right: 1.5em;
}

body[data-theme="dark"] .search-controls .team-select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23fff' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
}

.search-controls .team-select option {
  background-color: var(--light-bg-color);
  color: var(--light-text-color);
}

body[data-theme="dark"] .search-controls .team-select option {
  background-color: var(--dark-bg-color);
  color: var(--dark-text-color);
}

@media (max-width: 768px) {
  .search-controls {
    flex-direction: column;
  }

  .search-controls input,
  .search-controls .team-select {
    margin: 0.5rem 0;
    width: 100%;
  }
}

.search-controls select {
  flex: 1;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border: 1px solid var(--light-text-color);
  border-radius: 4px;
  font-size: 0.9em;
  background-color: var(--light-bg-color);
  color: var(--light-text-color);
  transition: all 0.3s ease;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23333' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%;
  background-size: 0.65em auto;
  padding-right: 2em;
}

body[data-theme="dark"] .search-controls select {
  border-color: var(--dark-text-color-alt);
  background-color: var(--dark-bg-color-alt);
  color: var(--dark-text-color);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23fff' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
}

.search-controls select:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgba(4, 232, 112, 0.2);
}

.search-controls select option {
  background-color: var(--light-bg-color);
  color: var(--light-text-color);
}

body[data-theme="dark"] .search-controls select option {
  background-color: var(--dark-bg-color);
  color: var(--dark-text-color);
}

@media (max-width: 768px) {
  .search-controls {
    flex-direction: column;
  }

  .search-controls input,
  .search-controls .team-select {
    margin: 0.5rem 0;
    width: 100%;
  }
}

.table-controls button:hover {
  background-color: #e0e0e0;
}

body[data-theme="dark"] .search-controls input::placeholder {
  color: var(--dark-text-color-alt);
}