.style-matchup-container {
  font-family: "Open Sans", sans-serif;
  max-width: 1200px;
  margin: 30px auto;
  background-color: var(--light-bg-color-alt);
  border-radius: 10px;
  transition: all 0.3s ease;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

body[data-theme="dark"] .style-matchup-container {
  background-color: var(--dark-bg-color);
}

.style-matchup-search-container {
  position: relative;
  margin-bottom: 20px;
}

.style-matchup-player-search {
  padding: 12px;
  font-size: 16px;
  width: 100%;
  max-width: 50%;
  border: 1px solid var(--light-text-color);
  border-radius: 4px;
  transition: all 0.3s ease;
  background-color: var(--light-bg-color);
  color: var(--light-text-color);
}

body[data-theme="dark"] .style-matchup-player-search {
  border-color: var(--dark-text-color);
  background-color: var(--dark-bg-color-alt);
  color: var(--dark-text-color);
}

.style-matchup-player-search:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgba(4, 232, 112, 0.2);
}

.style-matchup-player-search::placeholder {
  color: var(--light-text-color);
}

body[data-theme="dark"] .style-matchup-player-search::placeholder {
  color: var(--dark-text-color);
}

.style-matchup-search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-width: 300px;
  background-color: var(--light-bg-color);
  border: 1px solid var(--light-text-color);
  border-top: none;
  border-radius: 0 0 4px 4px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

body[data-theme="dark"] .style-matchup-search-results {
  background-color: var(--dark-bg-color);
  border-color: var(--dark-text-color);
}

.style-matchup-search-results li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.style-matchup-search-results li:hover {
  background-color: var(--light-bg-color);
}

body[data-theme="dark"] .style-matchup-search-results li:hover {
  background-color: var(--dark-bg-color-alt);
}

.style-matchup-result {
  background-color: var(--light-bg-color-alt);
  border: 1px solid var(--light-text-color);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

body[data-theme="dark"] .style-matchup-result {
  background-color: var(--dark-bg-color-alt);
  border-color: var(--dark-text-color);
}

.style-matchup-result h3 {
  margin-top: 0;
  color: var(--light-text-color);
  border-bottom: 2px solid var(--light-text-color);
  padding-bottom: 10px;
}

body[data-theme="dark"] .style-matchup-result h3 {
  color: var(--dark-text-color);
  border-bottom-color: var(--dark-text-color);
}

.style-matchup-top-matchups {
  margin-top: 20px;
}

.style-matchup-tabs {
  display: flex;
  margin-bottom: 1rem;
  border-radius: 4px;
  overflow: hidden;
}

.style-matchup-tab {
  flex: 1;
  padding: 0.75rem 1rem;
  border: none;
  background-color: var(--light-bg-color);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--light-text-color);
}

body[data-theme="dark"] .style-matchup-tab {
  background-color: var(--dark-bg-color-alt);
  color: var(--dark-text-color);
}

.style-matchup-tab.active {
  background-color: var(--accent-color);
  color: var(--light-text-color);
}

body[data-theme="dark"] .style-matchup-tab.active {
  background-color: var(--accent-color);
  color: var(--dark-text-color);
}

.style-matchup-tab:hover {
  opacity: 0.8;
}

.style-matchup-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;
}

.style-matchup-player-search,
.style-matchup-grade-filter {
  padding: 12px;
  font-size: 16px;
  border: 1px solid var(--light-text-color);
  border-radius: 4px;
  transition: all 0.3s ease;
  background-color: var(--light-bg-color);
  color: var(--light-text-color);
}

.style-matchup-player-search {
  flex-grow: 1;
  margin-right: 10px;
}

.style-matchup-grade-filter {
  width: 50%;
}

body[data-theme="dark"] .style-matchup-player-search,
body[data-theme="dark"] .style-matchup-grade-filter {
  border-color: var(--dark-text-color);
  background-color: var(--dark-bg-color-alt);
  color: var(--dark-text-color);
}

.style-matchup-player-search:focus,
.style-matchup-grade-filter:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgba(4, 232, 112, 0.2);
}

.style-matchup-heading {
  color: var(--light-text-color);
  border-bottom: 2px solid var(--light-text-color);
  padding-bottom: 10px;
}

body[data-theme="dark"] .style-matchup-heading {
  color: var(--dark-text-color);
  border-bottom-color: var(--dark-text-color);
}

.style-matchup-list {
  padding-left: 0;
  list-style-type: none;
}

.style-matchup-list-item {
  background-color: var(--light-bg-color);
  padding: 12px;
  margin-bottom: 10px;
  border-radius: 4px;
  transition: all 0.3s ease;
  cursor: pointer;
  overflow: hidden;
}

body[data-theme="dark"] .style-matchup-list-item {
  background-color: var(--dark-bg-color-alt);
}

.style-matchup-list-item:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.style-matchup-list-item-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.style-matchup-player-info {
  display: flex;
  flex-direction: column;
}

.style-matchup-list-item.expanded {
  padding-bottom: 0;
}

.style-matchup-details {
  background-color: var(--light-bg-color);
  margin: 10px -12px 0;
  padding: 12px;
}

body[data-theme="dark"] .style-matchup-details {
  background-color: var(--dark-bg-color-alt);
  border-top-color: var(--dark-text-color);
}

.style-matchup-details ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.style-matchup-details li {
  margin-bottom: 5px;
}

.style-matchup-player-name {
  font-weight: bold;
  color: var(--light-text-color);
}

body[data-theme="dark"] .style-matchup-player-name {
  color: var(--dark-text-color);
}

.style-matchup-opponent {
  color: var(--light-text-color);
  opacity: 0.7;
  margin-top: 5px;
}

body[data-theme="dark"] .style-matchup-opponent {
  color: var(--dark-text-color);
  opacity: 0.7;
}

.style-matchup-grade {
  font-weight: bold;
  font-size: 1.2rem;
  padding: 4px 8px;
  border-radius: 8px;
  color: var(--light-text-color);
  align-self: center;
}

body[data-theme="dark"] .style-matchup-grade {
  color: var(--dark-text-color);
}

.grade-ap { background-color: #08ec74; }
.grade-a { background-color: #2ecc71; }
.grade-am { background-color: #4fd18d; }
.grade-bp { background-color: #71d6a9; }
.grade-b { background-color: #93dbc5; }
.grade-bm { background-color: #b5e0e1; }
.grade-cp { background-color: #d7e5fd; }
.grade-c { background-color: #e0e0e0; } /* Grey midpoint */
.grade-cm { background-color: #f0d0d0; }
.grade-dp { background-color: #f5b0b0; }
.grade-d { background-color: #fa9090; }
.grade-dm { background-color: #ff7070; }
.grade-f { background-color: #ff5050; }

.style-matchup-buttons {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.style-matchup-button {
  padding: 0.5rem 1rem;
  background-color: var(--light-bg-color-alt);
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.style-matchup-button:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .style-matchup-container {
    margin: 0;
    border-radius: 0;
  }

  .style-matchup-controls {
    flex-direction: column;
  }

  .style-matchup-player-search,
  .style-matchup-grade-filter {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .style-matchup-tabs {
    overflow-x: auto;
  }

  .style-matchup-tab {
    flex: 1 0 33%;
    font-size: 14px;
  }

  .style-matchup-list-item-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .style-matchup-player-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .style-matchup-opponent {
    margin-top: 2px;
    font-size: 0.9em;
  }

  .style-matchup-grade {
    margin-left: auto;
  }
}